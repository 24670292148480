import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout displayFooter location={location}>
    <Helmet title="Recettes" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>Nos recettes</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sodales purus vel hendrerit sagittis. Mauris eu
        nibh pellentesque elit ultricies imperdiet luctus sit amet arcu. Phasellus cursus accumsan cursus. Aliquam diam
        risus, tempus nec orci ac, pretium lobortis risus. Integer mollis sit amet ipsum a sodales. In nisl erat,
        posuere in quam in, sodales tempor purus. Morbi aliquet laoreet ligula, a dapibus nulla. Integer et elit
        tincidunt, volutpat ligula ut, posuere dui. Phasellus faucibus aliquam nulla in ultricies. Aliquam mauris ex,
        lacinia id hendrerit vitae, volutpat eu lectus. Donec aliquam velit eget tellus consectetur, vel molestie lorem
        facilisis. Ut id enim at purus maximus imperdiet. Maecenas metus tortor, tincidunt ac velit sed, bibendum
        pharetra mi. Vestibulum eget luctus urna. Pellentesque in est non dolor luctus pulvinar.
      </p>
      <p>
        Sed posuere enim malesuada leo mollis, eu tempor libero sagittis. Mauris accumsan volutpat gravida. Phasellus
        molestie gravida consectetur. Morbi auctor metus quam. Quisque eu quam finibus, consectetur ipsum a, porta nisi.
        Sed vehicula ipsum sit amet scelerisque malesuada. In hac habitasse platea dictumst. Suspendisse congue
        ullamcorper mi, faucibus maximus urna scelerisque sit amet. Nulla nec auctor justo.
      </p>
      <p>
        Cras et finibus odio, et semper purus. Donec facilisis hendrerit nulla, a consequat nisl dictum sed. In sit amet
        vestibulum neque, id ultricies leo. Sed semper eget felis nec varius. In massa nunc, convallis quis enim ut,
        varius ornare neque. Nullam aliquet risus a iaculis luctus. Vivamus id urna eleifend eros luctus pellentesque.
        Aliquam gravida molestie nunc non ultricies. Etiam eleifend consectetur quam et viverra. Nunc sagittis, tortor
        id interdum rutrum, sapien ante bibendum ex, non cursus leo lorem a ipsum. Quisque sem lorem, feugiat sed quam
        id, lobortis aliquam nisl. Aenean aliquam velit et ultrices maximus. Vivamus vehicula imperdiet augue, quis
        viverra felis lacinia sit amet.
      </p>
    </section>
  </Layout>
);
